import { InformationCircleIcon, TrashIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { BsDoorOpen, BsProjector } from "react-icons/bs";
import { CgSmartHomeRefrigerator } from "react-icons/cg";
import { GiOfficeChair, GiVacuumCleaner, GiWindow } from "react-icons/gi";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import Wave from "react-wavify";
import Toast from "./components/toast";

function App() {
  const [list, setList] = useState([]);
  const [childList2, setChildList2] = useState([]);
  const [step, setStep] = useState(1);
  const [finalPrice, setFinalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [count, setCount] = useState(1);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedParentListName, setSelectedParentListName] = useState("");
  const [selectedParentListCode, setSelectedParentListCode] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [newIcon, setNewIcon] = useState("");
  const [showToast, setShowToast] = useState(false);

  const deviceTypes = [
    {
      id: "0",
      value: "000",
      name: "",
    },
    {
      id: "1",
      value: "001",
      name: "یخچال",
      icon: <CgSmartHomeRefrigerator className="size-8" />,
    },
    {
      id: "2",
      value: "002",
      name: "لباسشویی",
      icon: <MdOutlineLocalLaundryService className="size-8" />,
    },
    {
      id: "3",
      value: "003",
      name: "ظرفشویی",
      icon: (
        <img
          src="/assets/images/dishwasher.png"
          alt="dishwasher"
          className="size-8"
        />
      ),
    },
    {
      id: "4",
      value: "004",
      name: "کولر گازی",
      icon: (
        <img src="/assets/images/cooler.png" alt="cooler" className="size-8" />
      ),
    },
    {
      id: "5",
      value: "005",
      name: "تصفیه آب",
      icon: (
        <img
          src="/assets/images/water-purifier.png"
          alt="water-purifier"
          className="size-8"
        />
      ),
    },
    {
      id: "6",
      value: "006",
      name: "صندلی",
      icon: <GiOfficeChair className="size-8" />,
    },
    {
      id: "7",
      value: "007",
      name: "پروژکتور",
      icon: <BsProjector className="size-8" />,
    },
    {
      id: "8",
      value: "008",
      name: "درب",
      icon: <BsDoorOpen className="size-8" />,
    },
    {
      id: "9",
      value: "009",
      name: "جاروبرقی",
      icon: <GiVacuumCleaner className="size-8" />,
    },
    {
      id: "10",
      value: "010",
      name: "پنجره",
      icon: <GiWindow className="size-8" />,
    },
    {
      id: "11",
      value: "011",
      name: "کولر آبی",
      icon: (
        <img src="/assets/images/cooler.png" alt="cooler" className="size-8" />
      ),
    },
  ];

  const childList = [
    { value: "000", name: "", price: 0, parentID: "0", inYear: "0" },
    {
      value: "002",
      name: "صنعتی",
      price: 250_000_0,
      parentID: "001",
      inYear: 3,
    },
    {
      value: "003",
      name: "ویترینی",
      price: 250_000_0,
      parentID: "001",
      inYear: 3,
    },
    {
      value: "004",
      name: "ساید",
      price: 250_000_0,
      parentID: "001",
      inYear: 3,
    },
    {
      value: "005",
      name: "دوقلو",
      price: 250_000_0,
      parentID: "001",
      inYear: 3,
    },
    {
      value: "006",
      name: "معمولی",
      price: 250_000_0,
      parentID: "001",
      inYear: 3,
    },
    {
      value: "007",
      name: "سایر",
      price: 250_000_0,
      parentID: "001",
      inYear: 3,
    },
    {
      value: "008",
      name: "دوقلو",
      price: 185_000_0,
      parentID: "002",
      inYear: 3,
    },
    {
      value: "009",
      name: "اتوماتیک",
      price: 185_000_0,
      parentID: "002",
      inYear: 3,
    },
    {
      value: "010",
      name: "سطلی",
      price: 185_000_0,
      parentID: "002",
      inYear: 3,
    },
    {
      value: "010",
      name: "ظرفشویی",
      price: 230_000_0,
      parentID: "003",
      inYear: 3,
    },
    {
      value: "011",
      name: "12 هزار",
      price: 260_000_0,
      parentID: "004",
      inYear: 3,
    },
    {
      value: "012",
      name: "18 هزار",
      price: 260_000_0,
      parentID: "004",
      inYear: 3,
    },
    {
      value: "013",
      name: "24 هزار",
      price: 260_000_0,
      parentID: "004",
      inYear: 3,
    },
    {
      value: "014",
      name: "36 هزار",
      price: 260_000_0,
      parentID: "004",
      inYear: 3,
    },
    {
      value: "015",
      name: "60 هزار",
      price: 260_000_0,
      parentID: "004",
      inYear: 3,
    },
    {
      value: "016",
      name: "متصل به آب شهری",
      price: 310_000_0,
      parentID: "005",
      inYear: 2,
    },
    {
      value: "017",
      name: "مخزنی",
      price: 310_000_0,
      parentID: "005",
      inYear: 2,
    },
    {
      value: "018",
      name: "مدیریتی",
      price: 20_000_0,
      parentID: "006",
      inYear: 2,
    },
    {
      value: "019",
      name: "دانش آموزی",
      price: 20_000_0,
      parentID: "006",
      inYear: 2,
    },
    {
      value: "020",
      name: "راحتی",
      price: 20_000_0,
      parentID: "006",
      inYear: 2,
    },
    {
      value: "021",
      name: "کافه ای",
      price: 20_000_0,
      parentID: "006",
      inYear: 2,
    },
    {
      value: "022",
      name: "هوشمند",
      price: 400_000_0,
      parentID: "007",
      inYear: 1,
    },
    {
      value: "023",
      name: "غیر هوشمند",
      price: 400_000_0,
      parentID: "007",
      inYear: 1,
    },
    {
      value: "024",
      name: "نیمه هوشمند",
      price: 400_000_0,
      parentID: "007",
      inYear: 1,
    },
    {
      value: "025",
      name: "حیاطی",
      price: 10_000_0,
      parentID: "008",
      inYear: 2,
    },
    {
      value: "026",
      name: "گاراژی",
      price: 10_000_0,
      parentID: "008",
      inYear: 2,
    },
    { value: "027", name: "چوبی", price: 10_000_0, parentID: "008", inYear: 2 },
    {
      value: "028",
      name: "الومینیومی",
      price: 10_000_0,
      parentID: "008",
      inYear: 2,
    },
    {
      value: "029",
      name: "سطلی",
      price: 180_000_0,
      parentID: "009",
      inYear: 3,
    },
    {
      value: "030",
      name: "معمولی",
      price: 180_000_0,
      parentID: "009",
      inYear: 3,
    },
    {
      value: "031",
      name: "دوجداره",
      price: 7_000_0,
      parentID: "010",
      inYear: 2,
    },
    {
      value: "032",
      name: "معمولی",
      price: 7_000_0,
      parentID: "010",
      inYear: 2,
    },
    {
      value: "032",
      name: "3 هزار",
      price: 380_000_0,
      parentID: "011",
      inYear: 3,
    },
    {
      value: "032",
      name: "5 هزار",
      price: 380_000_0,
      parentID: "011",
      inYear: 3,
    },
    {
      value: "032",
      name: "6 هزار",
      price: 380_000_0,
      parentID: "011",
      inYear: 3,
    },
    {
      value: "032",
      name: "7 هزار",
      price: 380_000_0,
      parentID: "011",
      inYear: 3,
    },
    {
      value: "032",
      name: "سلولوزی",
      price: 380_000_0,
      parentID: "011",
      inYear: 3,
    },
    {
      value: "032",
      name: "پلیمری",
      price: 380_000_0,
      parentID: "011",
      inYear: 3,
    },
  ];

  const handleAddList = () => {
    const newList = {
      id: list.length + 1,
      deviceType: selectedParentListName,
      childListType: selectedItem,
      deviceID: selectedParentListCode,
      deviceNum: count,
      price,
    };

    if (
      !!newList.id &&
      !!newList.deviceType &&
      !!newList.childListType &&
      !!newList.deviceID &&
      !!newList.deviceNum &&
      !!newList.price
    ) {
      setList((prev) => [...prev, newList]);
    }

    setPrice(0);
    setCount(1);
    // setSelectedItem({});
    // setSelectedParentListName("");
    // setSelectedParentListCode("");
  };

  const handleChangeSelect = (e) => {
    setSelectedParentListName(e.target.options[e.target.selectedIndex].text);
    setSelectedParentListCode(e.target.value);

    let findIcon = deviceTypes.find((item) => {
      return item.value === e.target.value;
    });

    setNewIcon(findIcon);

    let child = childList.filter((item) => {
      return item.parentID === e.target.value;
    });

    setChildList2([
      // { value: "000", name: "", price: 0, parentID: "000", inYear: 0 },
      ...child,
    ]);
  };

  const handleDelete = () => {
    setList([]);
  };

  const deleteItem = (id) => {
    let newList = list.filter((item) => {
      return item.id !== id;
    });

    setList(newList);
  };

  useEffect(() => {
    console.log(childList2[0]);
    setSelectedItem(childList2[0]);
    setPrice(childList2[0]?.price);
  });

  return (
    <div className="w-full h-full lg:min-h-screen flex flex-col justify-between items-center">
      <header className="w-full flex justify-center shadow-md print:hidden">
        <div className="w-5/6 flex justify-between">
          <img
            src="/assets/images/Falogo.png"
            alt="logo"
            className="w-40 py-2"
          />
          <img
            src="/assets/images/EnLogo.png"
            alt="logo"
            className="w-40 py-2"
          />
        </div>
      </header>
      {step === 1 ? (
        <>
          <div className="xl:w-5/6 w-full h-auto shadow-md rounded-md p-4 flex lg:flex-row flex-col justify-between items-center mt-10 print:hidden">
            <div className="lg:w-10/12 w-full flex flex-col">
              <div className="w-full flex md:flex-row flex-col md:justify-start justify-center items-center">
                <label className="w-auto md:me-4 flex flex-col font-medium sm:items-start items-center">
                  نام شخص (حقیقی / حقوقی):
                  <input
                    type="text"
                    className="w-52 p-1 border-b border-black outline-none mt-2"
                    value={name}
                    placeholder="امین رضایی"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <span className="flex items-center text-sm text-red-500 mt-1.5">
                    <InformationCircleIcon className="size-4 text-red-500 me-1" />
                    این قسمت خالی نگذارید
                  </span>
                </label>
                <label className="w-auto flex flex-col md:mt-0 mt-4 font-medium sm:items-start items-center">
                  شماره تماس:
                  <input
                    type="text"
                    className="w-52 p-1 border-b border-black outline-none mt-2"
                    style={{ direction: "ltr" }}
                    value={phoneNumber}
                    placeholder="0910 355 6666"
                    onChange={(e) => {
                      const value = e.target.value;
                      const validate = RegExp("^09[0-9]{1,11}$");
                      if (e.target.value.length <= 11) {
                        setPhoneNumber(
                          validate.test(value) ? e.target.value : "09"
                        );
                      }
                    }}
                    onFocus={(e) => {
                      if (!!e.target.value === false) {
                        setPhoneNumber("09");
                      }
                    }}
                  />
                  <span className="flex items-center text-sm text-red-500 mt-1.5">
                    <InformationCircleIcon className="size-4 text-red-500 me-1" />
                    این قسمت خالی نگذارید
                  </span>
                </label>
              </div>
              <hr className="mt-8 w-11/12 md:block hidden" />
              <div className="w-full flex md:flex-row flex-col md:justify-start justify-center items-center mt-6">
                <label className="w-auto md:me-3 flex flex-col font-medium sm:items-start items-center">
                  نوع دستگاه:
                  <select
                    className="w-52 outline-none border-b border-black pb-2 mt-2"
                    onChange={(e) => {
                      handleChangeSelect(e);
                    }}
                  >
                    {deviceTypes.map((item, index) => (
                      <option
                        key={index}
                        value={item.value}
                        selected={index === 0 ? true : false}
                        className={`${index === 0 ? "hidden" : "flex"}`}
                      >
                        <span>{item?.icon}</span>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="w-auto md:me-3 md:mt-0 mt-4 flex flex-col font-medium sm:items-start items-center">
                  رده‌بندی:
                  <select
                    className="w-52 outline-none border-b border-black pb-2 mt-2"
                    onChange={(e) => {
                      let selectedDevice = childList2.find((item) => {
                        return item.value === e.target.value;
                      });
                      setSelectedItem(selectedDevice);
                      setPrice(selectedDevice.price);
                    }}
                    onClick={(e) => {
                      let selectedDevice = childList2?.find((item) => {
                        return item.value === e.target.value;
                      });
                      setSelectedItem(selectedDevice);
                      setPrice(selectedDevice?.price);
                    }}
                  >
                    {childList2?.map((item, index) => (
                      <option
                        key={index}
                        value={item.value}
                        className={`${
                          item.parentID === "000" ? "hidden" : "block"
                        }`}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="w-auto flex md:mt-0 mt-4 flex-col font-medium sm:items-start items-center">
                  تعداد دستگاه:
                  <input
                    type="number"
                    className="w-52 p-1 border-b border-black outline-none pb-2 mt-2"
                    value={count}
                    onChange={(e) => {
                      // setCount(e.target.value > 0 ? e.target.value : 1);
                      setCount(e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>
            {/* buttons */}
            <div className="xl:w-1/5 lg:w-2/5 w-full flex lg:flex-col md:flex-row flex-col lg:justify-center md:justify-end justify-center items-center lg:mt-0 mt-8">
              {!!selectedParentListName && (
                <div className="w-48 p-2 border border-blue-300 lg:mb-4 flex justify-between items-center rounded-xl">
                  <span>{selectedParentListName}</span>
                  {newIcon.icon}
                </div>
              )}
              <div className="w-full flex justify-center items-center font-medium py-2 lg:mb-2">
                <span className="w-full block text-center">
                  هزینه سرویس (ریال):{" "}
                  {!!price
                    ? (price * count * selectedItem.inYear).toLocaleString()
                    : 0}
                </span>
              </div>
              <div className="xl:w-auto md:w-[54%]">
                <button
                  className="w-24 h-12 bg-green-600 text-white rounded-md border-none outline-none me-2"
                  onClick={handleAddList}
                >
                  افزودن
                </button>
                <button
                  className="w-24 h-12 bg-red-500 text-white rounded-md lg:me-0 md:me-4"
                  onClick={handleDelete}
                >
                  حذف همه
                </button>
              </div>
            </div>
          </div>
          <div className="md:w-5/6 w-full h-full gap-5 p-4 rounded-lg mt-10 grid lg:grid-cols-2 grid-cols-1 justify-center">
            {list.map((item, index) => (
              <div
                key={index}
                className="w-full h-auto p-4 rounded-lg bg-white shadow-md flex flex-col"
              >
                <div className="w-full flex justify-between border-b border-dashed border-black pb-2">
                  <span className="font-medium">آیتم شماره {item.id}</span>
                  <TrashIcon
                    className="size-5 text-red-500 cursor-pointer"
                    onClick={() => {
                      deleteItem(item.id);
                    }}
                  />
                </div>
                <div className="w-full h-full flex flex-col">
                  <div className="w-full h-full flex items-start justify-center">
                    <span className="w-full py-2 font-medium">نوع دستگاه</span>
                    <span className="w-full py-2 text-end font-medium flex items-center justify-end">
                      {item.deviceType}
                    </span>
                  </div>
                  <div className="w-full h-full flex items-start justify-center">
                    <span className="w-full py-2 font-medium">رده بندی</span>
                    <span className="w-full py-2 text-end font-medium flex items-center justify-end">
                      {item.childListType.name}
                    </span>
                  </div>
                  <div className="w-full h-full flex items-start justify-center">
                    <span className="w-full py-2 font-medium">
                      تعداد دستگاه
                    </span>
                    <span className="w-full py-2 text-end font-medium flex items-center justify-end">
                      {item.deviceNum}
                    </span>
                  </div>
                  <div className="w-full h-full flex items-start justify-center">
                    <span className="w-full py-2 font-medium">
                      تعداد دفعات سرویس در سال
                    </span>
                    <span className="w-full py-2 text-end font-medium flex items-center justify-end">
                      {item.childListType.inYear}
                    </span>
                  </div>
                  <div className="w-full h-full flex items-start justify-center">
                    <span className="w-full py-2 font-medium">
                      هزینه سرویس (ریال)
                    </span>
                    <span className="w-full py-2 text-end font-medium flex items-center justify-end">
                      {(
                        item.price *
                        count *
                        item.childListType.inYear
                      ).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="lg:w-auto w-full lg:overflow-auto overflow-scroll print:overflow-hidden">
          <div className="w-[58rem] print:w-full min-h-96 mt-8 print:mt-0 flex flex-col justify-start">
            <div className="w-full h-auto flex flex-col">
              <span className="w-full block py-2 text-center text-xl">
                پیش فاکتور تعمیرستان 54
              </span>
            </div>
            <div className="w-full h-32 flex flex-col mb-4">
              <span className="w-full block py-2 text-center bg-blue-300">
                مشخصات خدمات گیرنده
              </span>
              <div className="w-full h-full grid grid-cols-2 p-4 bg-blue-200">
                <span>نام و نام خانوادگی: {name}</span>
                <span>شماره تماس: {phoneNumber}</span>
              </div>
            </div>
            <div className="w-full min-h-96  flex justify-start bg-blue-100">
              <div className="w-2/5 min-h-96 border border-black">
                <span className="w-full block font-medium text-center py-2 bg-white text-sm">
                  تعداد
                </span>
                {list.map((item, index) => (
                  <span
                    key={index}
                    className="w-full block font-medium text-center py-2 border-y border-black text-sm print:text-xs"
                  >
                    {item.id}
                  </span>
                ))}
              </div>
              <div className="w-2/5 min-h-96 border border-black text-sm print:text-xs">
                <span className="w-full block font-medium text-center py-2 bg-white text-sm">
                  کد کالا
                </span>
                {list.map((item, index) => (
                  <span
                    key={index}
                    className="w-full block font-medium text-center py-2 border-y border-black text-sm print:text-xs"
                  >
                    {item.deviceID}
                  </span>
                ))}
              </div>
              <div className="w-full min-h-96 border border-black text-sm print:text-xs">
                <span className="w-full block font-medium text-center py-2 bg-white text-sm">
                  دستگاه
                </span>
                {list.map((item, index) => (
                  <span
                    key={index}
                    className="w-full block font-medium text-center py-2 border-y border-black text-sm print:text-xs"
                  >
                    {item.deviceType} / {item?.childListType?.name}
                  </span>
                ))}
              </div>
              <div className="w-3/5 min-h-96 border border-black text-sm print:text-xs">
                <span className="w-full block font-medium text-center py-2 bg-white text-sm">
                  سرویس در سال
                </span>
                {list.map((item, index) => (
                  <span
                    key={index}
                    className="w-full block font-medium text-center py-2 border-y border-black text-sm print:text-xs"
                  >
                    {item.childListType.inYear}
                  </span>
                ))}
              </div>
              <div className="w-full min-h-96 border border-black text-sm print:text-xs">
                <span className="w-full block font-medium text-center py-2 bg-white text-sm">
                  تعداد و هزینه
                </span>
                {list.map((item, index) => (
                  <span
                    key={index}
                    className="w-full block font-medium text-center py-2 border-y border-black text-sm print:text-xs"
                  >
                    {item.deviceNum} / {item.price}
                  </span>
                ))}
              </div>
              <div className="w-full min-h-96 border border-black text-sm print:text-xs">
                <span className="w-full block font-medium text-center py-2 bg-white text-sm">
                  جمع (ریال)
                </span>
                {list.map((item, index) => (
                  <span
                    key={index}
                    className="w-full block font-medium text-center py-2 border-y border-black text-sm print:text-xs"
                  >
                    {(
                      item.price *
                      count *
                      item.childListType.inYear
                    ).toLocaleString()}
                  </span>
                ))}
              </div>
            </div>
            <div className="w-full h-14 border border-black text-sm print:text-xs flex justify-start items-center pr-4 font-medium">
              جمع کل (ریال): {finalPrice.toLocaleString()}
            </div>
            <div className="w-full h-14 border border-black text-sm print:text-xs flex justify-start items-center pr-4 font-medium">
              جمع کل همراه با مالیات (ریال):{" "}
              {(finalPrice + (finalPrice * 10) / 100).toLocaleString()}
            </div>
          </div>
        </div>
      )}
      <div className="mt-5">
        {step === 2 && (
          <button
            className="w-auto h-14 px-4 bg-green-500 text-white rounded-md me-4 print:hidden"
            onClick={() => window.print()}
          >
            پرینت
          </button>
        )}
        {step === 2 && (
          <button
            className="w-auto h-14 px-4 bg-red-500 text-white rounded-md me-4 print:hidden"
            onClick={() => {
              setStep(1);
              setFinalPrice(0);
            }}
          >
            بازگشت
          </button>
        )}
        {step === 1 && list.length !== 0 && (
          <button
            className={`w-auto h-14 px-4 text-white rounded-md me-4 print:hidden ${
              phoneNumber.length === 11 && !!name.length
                ? "bg-green-500 cursor-pointer"
                : "bg-green-300 cursor-auto"
            }`}
            onClick={() => {
              setStep(phoneNumber.length === 11 && !!name.length ? 2 : 1);
              setShowToast(
                phoneNumber.length === 11 && !!name.length ? false : true
              );
              list.forEach((item) => {
                setFinalPrice(
                  (prev) =>
                    item.price * count * item.childListType.inYear + prev
                );
              });
            }}
          >
            نمایش جدول
          </button>
        )}
      </div>

      <div className="w-full relative print:hidden mt-12">
        <Wave fill="#7dd3fc" speed={0.1} amplitude={40} points={8}></Wave>
        <Wave
          fill="#38bdf8"
          className="absolute right-0"
          amplitude={50}
          points={10}
        ></Wave>
      </div>
      <Toast setShowToast={setShowToast} showToast={showToast} />
    </div>
  );
}

export default App;
