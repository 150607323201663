import React from "react";
import { CiCircleInfo } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";

function Toast(props) {
  const { setShowToast, showToast } = props;
  return (
    <div
      className={`md:w-96 w-full h-20 right-0 ${
        !!showToast === false
          ? "md:top-0 md:-right-full -top-24"
          : "md:top-0 md:right-0 top-0"
      } fixed bg-yellow-200 flex justify-center items-center`}
    >
      <div className="w-full flex justify-center items-center">
        <CiCircleInfo className="size-5 me-2" />
        <span className="text-base">لطفا مشخصات خود را به درستی وارد کنید</span>
      </div>
      <IoMdClose
        className="size-5 me-2"
        onClick={() => {
          setShowToast(false);
        }}
      />
    </div>
  );
}

export default Toast;
